import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Table, ProgressBar } from "react-bootstrap";
import DB from "./db";

const MonsCompareComponent = () => {
  const params = useParams();
  const [monId1, setMonId1] = useState(params.id1);
  const [mon1, setMon1] = useState(null);
  const [monId2, setMonId2] = useState(params.id2);
  const [mon2, setMon2] = useState(null);
  const navigate = useNavigate();

  const fetchData = () => {
    // Fetch data from API using the monId
    // Replace this with your actual API call
    setMon1({ loading: true });
    fetch(`https://nutdex-proxy.vercel.app/metadata/monsters/${monId1}`)
      .then((response) => {
        console.log(response);
        if (response.ok) {
          return response.text();
        } else {
          setMon1({ error: "ID invalid." });
          throw new Error("Network response was not OK.");
        }
      })
      .then((responseData) => {
        // Store the fetched data in the state
        setMon1(JSON.parse(responseData));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });

    setMon2({ loading: true });
    fetch(`https://nutdex-proxy.vercel.app/metadata/monsters/${monId2}`)
      .then((response) => {
        console.log(response);
        if (response.ok) {
          return response.text();
        } else {
          setMon2({ error: "ID invalid." });
          throw new Error("Network response was not OK.");
        }
      })
      .then((responseData) => {
        // Store the fetched data in the state
        setMon2(JSON.parse(responseData));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    if (mon1 === null && monId1 && mon2 === null && monId2) {
      fetchData();
    }
  }, []);

  const handleInputChange1 = (event) => {
    setMonId1(event.target.value);
  };
  const handleInputChange2 = (event) => {
    setMonId2(event.target.value);
  };

  const handleButtonClick = () => {
    if (monId1 && monId2) {
      fetchData();
      navigate("/nft/compare/" + monId1 + "/" + monId2);
    }
  };

  return (
    <Col>
      <Row className="py-2 bg-success">
        <Col>
          <input
            className="w-100"
            type="text"
            value={monId1}
            onChange={handleInputChange1}
          />
        </Col>
        <Col>
          <input
            className="w-100"
            type="text"
            value={monId2}
            onChange={handleInputChange2}
          />
        </Col>
        <Col>
          <button className="w-100" onClick={handleButtonClick}>
            Search
          </button>
        </Col>
      </Row>
      {mon1 &&
        !mon1.loading &&
        !mon1.error &&
        mon2 &&
        !mon2.loading &&
        !mon2.error && (
          <>
            <Row>
              <Col className="text-center bg-success col-6 px-0">
                <img
                  alt={mon1.name}
                  className="w-100"
                  width="250"
                  height="250"
                  src={mon1.image}
                />
                <h5 className="mb-0 text-white">{mon1.name}</h5>
              </Col>
              <Col className="text-center bg-success col-6 px-0">
                <img
                  alt={mon2.name}
                  className="w-100"
                  width="250"
                  height="250"
                  src={mon2.image}
                />
                <h5 className="mb-0 text-white">{mon2.name}</h5>
              </Col>
            </Row>
            <Row>
              <Col className="px-0 col-12">
                <Table id="mons-compare" className="mb-0">
                  <tbody>
                    {rowAttr(mon1, mon2, "Innate ATK")}
                    {rowAttr(mon1, mon2, "Innate DEF")}
                    {rowAttr(mon1, mon2, "Innate HP")}
                    {rowAttr(mon1, mon2, "Innate sATK")}
                    {rowAttr(mon1, mon2, "Innate sDEF")}
                    {rowAttr(mon1, mon2, "Innate SP")}
                    {rowAttr(mon1, mon2, "Innate SPE")}
                    <tr>
                      <td className="text-white p-1 align-middle">
                        <ProgressBar
                          variant="secondary"
                          striped
                          now={calcPerc(
                            attribute(mon1, "Innate ATK") +
                              attribute(mon1, "Innate DEF") +
                              attribute(mon1, "Innate HP") +
                              attribute(mon1, "Innate sATK") +
                              attribute(mon1, "Innate sDEF") +
                              attribute(mon1, "Innate SP") +
                              attribute(mon1, "Innate SPE"),
                            7 * 63
                          )}
                          label={
                            attribute(mon1, "Innate ATK") +
                            attribute(mon1, "Innate DEF") +
                            attribute(mon1, "Innate HP") +
                            attribute(mon1, "Innate sATK") +
                            attribute(mon1, "Innate sDEF") +
                            attribute(mon1, "Innate SP") +
                            attribute(mon1, "Innate SPE") +
                            "/" +
                            7 * 63
                          }
                        />
                      </td>

                      <td className="text-white text-center  p-1">Total</td>

                      <td className="text-white p-1 align-middle">
                        <ProgressBar
                          className="justify-content-end"
                          variant="secondary"
                          striped
                          now={calcPerc(
                            attribute(mon2, "Innate ATK") +
                              attribute(mon2, "Innate DEF") +
                              attribute(mon2, "Innate HP") +
                              attribute(mon2, "Innate sATK") +
                              attribute(mon2, "Innate sDEF") +
                              attribute(mon2, "Innate SP") +
                              attribute(mon2, "Innate SPE"),
                            7 * 63
                          )}
                          label={
                            attribute(mon2, "Innate ATK") +
                            attribute(mon2, "Innate DEF") +
                            attribute(mon2, "Innate HP") +
                            attribute(mon2, "Innate sATK") +
                            attribute(mon2, "Innate sDEF") +
                            attribute(mon2, "Innate SP") +
                            attribute(mon2, "Innate SPE") +
                            "/" +
                            7 * 63
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-white p-1 align-middle">
                        {attribute(mon1, "Ability")}
                        <span
                          className="badge rounded-pill fw-normal align-middle mx-2"
                          style={{
                            color:
                              DB.Abilities[attribute(mon1, "Ability")].grade
                                .color,
                            backgroundColor:
                              DB.Abilities[attribute(mon1, "Ability")].grade.bg,
                          }}
                        >
                          {DB.Abilities[attribute(mon1, "Ability")].grade.name}
                        </span>
                      </td>
                      <td className="text-white text-center  p-1">Ability</td>
                      <td className="text-white p-1 text-end align-middle">
                        <span
                          className="badge rounded-pill fw-normal align-middle mx-2"
                          style={{
                            color:
                              DB.Abilities[attribute(mon2, "Ability")].grade
                                .color,
                            backgroundColor:
                              DB.Abilities[attribute(mon2, "Ability")].grade.bg,
                          }}
                        >
                          {DB.Abilities[attribute(mon2, "Ability")].grade.name}
                        </span>
                        {attribute(mon2, "Ability")}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-white p-1 align-middle">
                        {attribute(mon1, "Variant")}
                      </td>
                      <td className="text-white text-center  p-1">Variant</td>
                      <td className="text-white p-1 text-end align-middle">
                        {attribute(mon2, "Variant")}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-white p-1 align-middle">
                        {attribute(mon1, "Breed")}
                      </td>
                      <td className="text-white text-center  p-1">Breed</td>
                      <td className="text-white p-1 text-end align-middle">
                        {attribute(mon2, "Breed")}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-white p-1 align-middle">
                        <ProgressBar
                          striped
                          now={calcPerc(
                            attribute(mon1, "Mutation Stage"),
                            attributeRaw(mon1, "Mutation Stage").max_value
                          )}
                          label={
                            attribute(mon1, "Mutation Stage") +
                            "/" +
                            attributeRaw(mon1, "Mutation Stage").max_value
                          }
                        />
                      </td>
                      <td className="text-white text-center p-1">Stage</td>
                      <td className="text-white p-1 align-middle">
                        <ProgressBar
                          className="justify-content-end"
                          striped
                          now={calcPerc(
                            attribute(mon2, "Mutation Stage"),
                            attributeRaw(mon2, "Mutation Stage").max_value
                          )}
                          label={
                            attribute(mon2, "Mutation Stage") +
                            "/" +
                            attributeRaw(mon2, "Mutation Stage").max_value
                          }
                        />
                      </td>
                    </tr>
                    <tr>
                      <td className="text-white p-1 align-middle">
                        {attribute(mon1, "Gender")}
                      </td>
                      <td className="text-white text-center  p-1">Gender</td>
                      <td className="text-white p-1 text-end align-middle">
                        {attribute(mon2, "Gender")}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-white p-1 align-top">
                        {attribute(mon1, "Personality")}
                      </td>
                      <td className="text-white text-center  p-1">
                        Personality
                      </td>
                      <td className="text-white p-1 text-end align-top">
                        {attribute(mon2, "Personality")}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-white p-1 align-middle">
                        {attribute(mon1, "Likes")}
                      </td>
                      <td className="text-white text-center  p-1">Likes</td>
                      <td className="text-white p-1 text-end align-middle">
                        {attribute(mon2, "Likes")}
                      </td>
                    </tr>
                    <tr>
                      <td className="text-white p-1 align-middle">
                        {attribute(mon1, "Dislikes")}
                      </td>
                      <td className="text-white text-center  p-1">Dislikes</td>
                      <td className="text-white p-1 text-end align-middle">
                        {attribute(mon2, "Dislikes")}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
      {((mon1 && mon1.loading) || (mon2 && mon2.loading)) && (
        <div className="text-center p-5">Loading...</div>
      )}
      {((mon1 && mon1.error) || (mon2 && mon2.error)) && (
        <>
          <div className="text-center p-5">Error: {mon1.error}</div>
          <div className="text-center p-5">Error: {mon2.error}</div>
        </>
      )}
      {!mon1 && <div className="text-center p-5">Input 2 mon IDs.</div>}
    </Col>
  );
};

function rowAttr(mon1, mon2, attr) {
  return (
    <>
      <tr>
        <td className="text-white p-1 align-middle">
          <ProgressBar
            striped
            now={calcPerc(attribute(mon1, attr), 63)}
            label={attribute(mon1, attr)}
          />
        </td>
        <td className="text-white text-center p-1">
          {attr.replace("Innate ", "")}
        </td>
        <td className="text-white p-1 align-middle">
          <ProgressBar
            className="justify-content-end"
            striped
            now={calcPerc(attribute(mon2, attr), 63)}
            label={attribute(mon2, attr)}
          />
        </td>
      </tr>
    </>
  );
}

function attribute(mon, trait_type) {
  return mon.attributes.filter((obj) => obj.trait_type === trait_type)[0].value;
}
function attributeRaw(mon, trait_type) {
  return mon.attributes.filter((obj) => obj.trait_type === trait_type)[0];
}

function calcPerc(attr, max) {
  return (attr / max) * 100;
}

export default MonsCompareComponent;
