export default class DB {
  static Types = {
    Water: {
      name: "Water",
      color: "#000000",
      bg: "#87CEEB",
    },
    Heavy: {
      name: "Heavy",
      color: "#FFFFFF",
      bg: "#3F3F3F",
    },
    Land: {
      name: "Land",
      color: "#FFFFFF",
      bg: "#7A4E2B",
    },
    Fire: {
      name: "Fire",
      color: "#FFFFFF",
      bg: "#FF3F3F",
    },
    Ice: {
      name: "Ice",
      color: "#F2F2F2",
      bg: "#020202",
    },
    Apocalyptic: {
      name: "Apocalyptic",
      color: "#FFFFFF",
      bg: "#A52727",
    },
    Poison: {
      name: "Poison",
      color: "#FFFFFF",
      bg: "#8B00B2",
    },
    Air: {
      name: "Air",
      color: "#FFFFFF",
      bg: "#B0E2FF",
    },
    Electric: {
      name: "Electric",
      color: "#000000",
      bg: "#F7FF3F",
    },
  };

  static Personalities = {
    Cheerful: {
      name: "Cheerful",
      color: "#000000",
      bg: "#87CEEB",
    },
    Friendly: {
      name: "Friendly",
      color: "#000000",
      bg: "#FFC0CB",
    },
    Naive: {
      name: "Naive",
      color: "#000000",
      bg: "#ADFF2F",
    },
    LoneWolf: {
      name: "Lone Wolf",
      color: "#FFFFFF",
      bg: "#FF4500",
    },
    Arrogant: {
      name: "Arrogant",
      color: "#000000",
      bg: "#FFFF00",
    },
    Controlling: {
      name: "Controlling",
      color: "#FFFFFF",
      bg: "#8A2BE2",
    },
    Smart: {
      name: "Smart",
      color: "#000000",
      bg: "#00FF00",
    },
    Quick: {
      name: "Quick",
      color: "#000000",
      bg: "#FF69B4",
    },
    Angry: {
      name: "Angry",
      color: "#000000",
      bg: "#1E90FF",
    },
    Leader: {
      name: "Leader",
      color: "#000000",
      bg: "#FFD700",
    },
    Cute: {
      name: "Cute",
      color: "#FFFFFF",
      bg: "#8B008B",
    },
    Emo: {
      name: "Emo",
      color: "#000000",
      bg: "#00FFFF",
    },
    Innocent: {
      name: "Innocent",
      color: "#000000",
      bg: "#FF8C00",
    },
    Intelligent: {
      name: "Intelligent",
      color: "#000000",
      bg: "#7FFF00",
    },
  };

  static Mons = {
    /*Dynorat: {
      name: "Dynorat",
      id: "??",
      type1: this.Types.Land,
      type2: this.Types.Fire,
      img: require("./img/dynorat.gif"),
    },*/
    Bower: {
      name: "Bower",
      id: "37",
      type1: this.Types.Water,
      type2: this.Types.Heavy,
      img: require("./img/37_Bower.gif"),
    },
    Sobek: {
      name: "Sobek",
      id: "24",
      type1: this.Types.Water,
      type2: this.Types.Ice,
      img: require("./img/24_Sobek.gif"),
    },
    Paddle: {
      name: "Paddle",
      id: "71",
      type1: this.Types.Water,
      type2: this.Types.Ice,
      img: require("./img/71_Paddle.gif"),
    },
    Ignipuff: {
      name: "Ignipuff",
      id: "??",
      type1: this.Types.Land,
      type2: this.Types.Fire,
      img: require("./img/ignipuff.gif"),
    },
    Empod: {
      name: "Empod",
      id: "50",
      type1: this.Types.Water,
      type2: this.Types.Heavy,
      img: require("./img/50_Empod.gif"),
    },
    Mutwig: {
      name: "Mutwig",
      id: "93",
      type1: this.Types.Land,
      type2: this.Types.Heavy,
      img: require("./img/mutwig.gif"),
    },
    Kaiju: {
      name: "Kaiju",
      id: "68",
      type1: this.Types.Land,
      type2: this.Types.Fire,
      img: require("./img/68_kaiju.gif"),
    },
    MoonCat: {
      name: "MoonCat",
      id: "89",
      type1: this.Types.Land,
      type2: this.Types.Apocalyptic,
      img: require("./img/89_Mooncat.gif"),
    },
    Presleaf: {
      name: "Presleaf",
      id: "??",
      type1: this.Types.Land,
      type2: this.Types.Poison,
      img: require("./img/presleaf.gif"),
    },
    Skelefish: {
      name: "Skelefish",
      id: "98",
      type1: this.Types.Water,
      type2: this.Types.Apocalyptic,
      img: require("./img/98_skelefish.gif"),
    },
    Woodot: {
      name: "Woodot",
      id: "92",
      type1: this.Types.Land,
      type2: this.Types.Heavy,
      img: require("./img/92_wood_frog.gif"),
    },
    CyberKong: {
      name: "CyberKong",
      id: "86",
      type1: this.Types.Land,
      type2: this.Types.Apocalyptic,
      img: require("./img/86_cyberkong.gif"),
    },
    Alpacabra: {
      name: "Alpacabra",
      id: "83",
      type1: this.Types.Land,
      type2: this.Types.Apocalyptic,
      img: require("./img/83_alpaca.gif"),
    },
    Wisp: {
      name: "Wisp",
      id: "101",
      type1: this.Types.Air,
      type2: this.Types.Electric,
      img: require("./img/101_wisp.gif"),
    },
    Yeti: {
      name: "Yeti",
      id: "74",
      type1: this.Types.Land,
      type2: this.Types.Ice,
      img: require("./img/74_yeti.gif"),
    },
    Pantheor: {
      name: "Pantheor",
      id: "25",
      type1: this.Types.Land,
      type2: this.Types.Ice,
      img: require("./img/25_Pantheor.gif"),
    },
    Azathoth: {
      name: "Azathoth",
      id: "70",
      type1: this.Types.Land,
      type2: this.Types.Fire,
      img: require("./img/70_kaiju_stage_3.gif"),
    },
    Frostfoot: {
      name: "Frostfoot",
      id: "75",
      type1: this.Types.Land,
      type2: this.Types.Ice,
      img: require("./img/75_yeti_stage_2.gif"),
    },
    Mujira: {
      name: "Mujira",
      id: "78",
      type1: this.Types.Land,
      type2: this.Types.Electric,
      img: require("./img/78_godjira_stage_2.gif"),
    },
    Gojira: {
      name: "Gojira",
      id: "79",
      type1: this.Types.Land,
      type2: this.Types.Ice,
      img: require("./img/79_gojira.gif"),
    },
    Skitten: {
      name: "Skitten",
      id: "13",
      type1: this.Types.Land,
      type2: this.Types.Electric,
      img: require("./img/13_Skitten.gif"),
    },
    Snetail: {
      name: "Snetail",
      id: "28",
      type1: this.Types.Land,
      type2: this.Types.Poison,
      img: require("./img/28_Snetail.gif"),
    },
    Lupin: {
      name: "Lupin",
      id: "94",
      type1: this.Types.Land,
      type2: this.Types.Heavy,
      img: require("./img/Lupin.gif"),
    },
    Anura: {
      name: "Anura",
      id: "??",
      type1: this.Types.Water,
      type2: this.Types.Poison,
      img: require("./img/anura.gif"),
    },
    Mundor: {
      name: "Mundor",
      id: "??",
      type1: this.Types.Land,
      type2: this.Types.Poison,
      img: require("./img/Mundor.gif"),
    },
    Scuberant: {
      name: "Scuberant",
      id: "??",
      type1: this.Types.Water,
      type2: this.Types.Heavy,
      img: require("./img/Scuberant_front.gif"),
    },
    Kitsune: {
      name: "Kitsune",
      id: "??",
      type1: this.Types.Land,
      type2: this.Types.Ice,
      img: require("./img/Kitsune_front.gif"),
    },
    Pixiu: {
      name: "Pixiu",
      id: "??",
      type1: this.Types.Land,
      type2: this.Types.Heavy,
      img: require("./img/Pixiu_front.gif"),
    },
    Quinbee: {
      name: "Quinbee",
      id: "??",
      type1: this.Types.Air,
      type2: this.Types.Electric,
      img: require("./img/Quinbee_front.gif"),
    },
    Entomore: {
      name: "Entomore",
      id: "??",
      type1: this.Types.Land,
      type2: this.Types.Poison,
      img: require("./img/Entomore_front.gif"),
    },
    Icedeor: {
      name: "Icedeor",
      id: "26",
      type1: this.Types.Land,
      type2: this.Types.Ice,
      img: require("./img/icedeor.gif"),
    },
    Mishupashu: {
      name: "Mishupashu",
      id: "27",
      type1: this.Types.Land,
      type2: this.Types.Ice,
      img: require("./img/Pantheorstage_3_front.gif"),
    },
    Tankerole: {
      name: "Tankerole",
      id: "??",
      type1: this.Types.Land,
      type2: this.Types.Heavy,
      img: require("./img/Tankerole_front.gif"),
    },
    Khonsur: {
      name: "Khonsur",
      id: "??",
      type1: this.Types.Air,
      type2: this.Types.Apocalyptic,
      img: require("./img/Khonsur.gif"),
    },
    Lunaria: {
      name: "Lunaria",
      id: "90",
      type1: this.Types.Land,
      type2: this.Types.Apocalyptic,
      img: require("./img/90_Lunaria.gif"),
    },
    Horus: {
      name: "Horus",
      id: "??",
      type1: this.Types.Air,
      type2: this.Types.Apocalyptic,
      img: require("./img/horus.gif"),
    },
  };

  static Abilities_Grade = {
    Common: {
      name: "Common",
      color: "#fff",
      bg: "#aaa",
    },
    Uncommon: {
      name: "Uncommon",
      color: "#fff",
      bg: "#32CD32",
    },
    Rare: {
      name: "Rare",
      color: "#fff",
      bg: "#FFD700",
    },
  };

  static Abilities = {
    "Venom Barbed": {
      name: "Venom Barbed",
      grade: this.Abilities_Grade.Common,
    },
    Frosty: {
      name: "Frosty",
      grade: this.Abilities_Grade.Common,
    },
    Livewire: {
      name: "Livewire",
      grade: this.Abilities_Grade.Common,
    },
    Liquidator: {
      name: "Liquidator",
      grade: this.Abilities_Grade.Uncommon,
    },
    Amplifier: {
      name: "Amplifier",
      grade: this.Abilities_Grade.Uncommon,
    },
    "Vein Sensor": {
      name: "Vein Sensor",
      grade: this.Abilities_Grade.Uncommon,
    },
    Venoglutton: {
      name: "Venoglutton",
      grade: this.Abilities_Grade.Rare,
    },
    Aquabyssal: {
      name: "Aquabyssal",
      grade: this.Abilities_Grade.Rare,
    },
    Electrocharge: {
      name: "Electrocharge",
      grade: this.Abilities_Grade.Rare,
    },
  };

  static Trainers = {
    Beacon: {
      name: "Beacon",
      type1: this.Personalities.Cheerful,
      type2: this.Personalities.Friendly,
      type3: this.Personalities.Naive,
      img: require("./trainers/Beacon.png"),
    },

    Nucleo: {
      name: "Nucleo",
      type1: this.Personalities.LoneWolf,
      type2: this.Personalities.Arrogant,
      type3: this.Personalities.Controlling,
      img: require("./trainers/nucleo.png"),
    },

    Leo: {
      name: "Leo",
      type1: this.Personalities.Smart,
      type2: this.Personalities.Arrogant,
      type3: this.Personalities.Quick,
      img: require("./trainers/leo.png"),
    },

    Hercules: {
      name: "Hercules",
      type1: this.Personalities.Arrogant,
      type2: this.Personalities.Angry,
      type3: this.Personalities.LoneWolf,
      img: require("./trainers/Hercules.png"),
    },

    Archer: {
      name: "Archer",
      type1: this.Personalities.Leader,
      type2: this.Personalities.Smart,
      type3: this.Personalities.Quick,
      img: require("./trainers/archer.png"),
    },

    Celena: {
      name: "Celena",
      type1: this.Personalities.Cheerful,
      type2: this.Personalities.Quick,
      type3: this.Personalities.Cute,
      img: require("./trainers/celena.png"),
    },

    Luna: {
      name: "Luna",
      type1: this.Personalities.Friendly,
      type2: this.Personalities.Innocent,
      type3: this.Personalities.Cute,
      img: require("./trainers/luna.png"),
    },

    Hurley: {
      name: "Hurley",
      type1: this.Personalities.Cheerful,
      type2: this.Personalities.Emo,
      type3: this.Personalities.Naive,
      img: require("./trainers/hurley.png"),
    },

    Astra: {
      name: "Astra",
      type1: this.Personalities.Smart,
      type2: this.Personalities.Quick,
      type3: this.Personalities.Arrogant,
      img: require("./trainers/astra.png"),
    },

    Danica: {
      name: "Danica",
      type1: this.Personalities.Cute,
      type2: this.Personalities.Cheerful,
      type3: this.Personalities.Naive,
      img: require("./trainers/danica.png"),
    },

    Apollo: {
      name: "Apollo",
      type1: this.Personalities.Cute,
      type2: this.Personalities.Innocent,
      type3: this.Personalities.Cheerful,
      img: require("./trainers/apollo.png"),
    },

    Aurora: {
      name: "Aurora",
      type1: this.Personalities.Cute,
      type2: this.Personalities.Quick,
      type3: this.Personalities.Intelligent,
      img: require("./trainers/Aurora.png"),
    },
  };
}

/* TRAINER TEMPLATE

    ______: {
      name: "______",
      type1: this.Personalities.,
      type2: this.Personalities.,
      type3: this.Personalities.,
      img: require("./trainers/"),
    },

/*

/* MON TEMPLATE

    ______: {
      name: "______",
      id: "??",
      type1: this.Types.,
      type2: this.Types.,
      img: require("./img/"),
    },

*/
