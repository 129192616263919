import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Legend } from 'chart.js';

// Register components from Chart.js that you will use
ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Legend);

// Custom plugin to always show stat names and values near dots
const statLabelPlugin = {
  id: 'statLabelPlugin',
  afterDraw: (chart) => {
    const { ctx, scales: { r } } = chart;
    const dataset = chart.data.datasets[0];
    const labels = chart.data.labels;

    ctx.save();
    ctx.font = 'bold 16px VT323'; // Bold and large font for labels and values
    ctx.fillStyle = '#0d022f'; // Text color for stat names and values

    dataset.data.forEach((value, index) => {
      const anglePosition = r.getPointPositionForValue(index, dataset.data[index]);
      const statName = labels[index];

      // Measure text width and height for the labels
      const textMetrics = ctx.measureText(statName);
      const textWidth = textMetrics.width;
      const textHeight = 16; // Approximate text height (depends on font size)

      // Get label position slightly outside the chart based on the maximum value and an offset
      const labelPosition = r.getPointPositionForValue(index, r.max + 5);

      // Calculate angle to ensure the label is properly placed outside the radar chart
      const angle = Math.atan2(labelPosition.y - r.yCenter, labelPosition.x - r.xCenter);

      // Calculate dynamic offsets based on the angle and adjust them to center the labels
      const distance = 10; // Set the distance outside the chart
      const xOffset = distance * Math.cos(angle); // X offset based on angle
      const yOffset = distance * Math.sin(angle); // Y offset based on angle

      // Adjust label position to center it around the text's width and height
      const adjustedX = labelPosition.x + xOffset - (textWidth / 2); // Center horizontally
      const adjustedY = labelPosition.y + yOffset + (textHeight / 2); // Center vertically

      // Place the label at an appropriate position relative to the angle
      ctx.fillText(statName, adjustedX, adjustedY);

      // Show the value near the data point
      const valuePosition = r.getPointPositionForValue(index, dataset.data[index]);
      const valueWidth = ctx.measureText(`${value}`).width;

      // Use anchor point to determine the offset for value positioning
      const valueOffset = 20; // Increased offset from the data point
      const valueAngle = Math.atan2(valuePosition.y - r.yCenter, valuePosition.x - r.xCenter);
      const valueXOffset = valueOffset * Math.cos(valueAngle);
      const valueYOffset = valueOffset * Math.sin(valueAngle);

      // Adjust vertical positioning to avoid overlap and keep values clear
      const adjustedValueX = valuePosition.x + valueXOffset - (valueWidth / 2); // Center horizontally
      const adjustedValueY = valuePosition.y + valueYOffset + 10; // Adjust vertically to ensure clarity

      ctx.fillText(`${value}`, adjustedValueX, adjustedValueY);
    });

    ctx.restore();
  }
};

const RadarChart = ({ mon }) => {
  const data = {
    labels: ['HP', 'SP', 'sATK', 'sDEF', 'SPD', 'DEF', 'ATK'],
    datasets: [
      {
        label: '', // No label to remove legend
        data: [
          mon.Stats.HP,
          mon.Stats.SP,
          mon.Stats.sATK,
          mon.Stats.sDEF,
          mon.Stats.SPD,
          mon.Stats.DEF,
          mon.Stats.ATK
        ],
        backgroundColor: 'rgba(255, 243, 204, 0.4)', // Yellow background
        borderColor: '#fff3cc', // Yellow border for chart lines
        pointBackgroundColor: '#fff3cc', // Yellow dots
        borderWidth: 2
      }
    ]
  };

  const options = {
    plugins: {
      legend: {
        display: false, // Remove the legend
      }
    },
    scales: {
      r: {
        beginAtZero: true,
        suggestedMax: 150, // Adjust based on your stats max
        ticks: {
          display: false, // Remove the numbers like "100"
          showLabelBackdrop: false, // Removes the label backdrop (box behind numbers)
          maxTicksLimit: 8, // Show a few grid lines
        },
        grid: {
          circular: false, // Non-circular grid
          color: (context) => context.tick.value === context.scale.max ? '#712cf9' : 'rgba(0, 0, 0, 0.2)', // Purple outermost, light lines for others
          lineWidth: (context) => context.tick.value === context.scale.max ? 3 : 1, // Thicker outermost line
        },
        angleLines: {
          display: true, // Show radial lines from center to border
          color: 'rgba(0, 0, 0, 0.2)', // Light gray radial lines
          lineWidth: 1, // Thickness of the radial lines
        },
        pointLabels: {
          display: false // Hide the labels (HP, SP, etc.) around the chart
        },
        startAngle: -360 / 14, // Rotate the chart to place HP and SP at the top
      }
    },
    elements: {
      point: {
        radius: 5, // Size of the points (dots)
        hoverRadius: 8, // Size when hovering
        display: true,
      }
    },
    layout: {
      padding: {
        top: 30, // Increase padding to prevent cutoff
        bottom: 30, // Adjust as needed
        left: 30, // Adjust as needed
        right: 30, // Adjust as needed
      }
    },
    animation: {
      duration: 0, // Disable animation
    }
  };
  
  

  return <Radar data={data} options={options} plugins={[statLabelPlugin]} />;
};

export default RadarChart;
