import data from "./data/data.min.json";

export default class BOK {
  static Monster = {
    getAll: () => {
      return data.sheets.find((sheet) => sheet.name === "Monster").lines;
    },
    getByIndex: (index) => {
      index = index + "";
      return this.Monster.getAll().find((mon) => mon.INDEX === index);
    },
    getByName: (name) => {
      name = name + "";
      return this.Monster.getAll().find((mon) => mon.id === name);
    },
    getByGuid: (guid) => {
      return this.Monster.getAll().find((mon) => mon.guid === guid);
    },
  };
  static Element = {
    getAll: () => {
      return data.sheets.find((sheet) => sheet.name === "Element").lines;
    },
    getByGuid: (guid) => {
      return this.Element.getAll().find((element) => element.guid === guid);
    },
  };
  static Mutation = {
    getAll: () => {
      return data.sheets.find((sheet) => sheet.name === "Mutation").lines;
    },
    getMutationsFromByGuid: (guid) => {
      return data.sheets
        .find((sheet) => sheet.name === "Mutation")
        .lines.filter((mutation) => mutation.To === guid);
    },
    getMutationsToByGuid: (guid) => {
      return data.sheets
        .find((sheet) => sheet.name === "Mutation")
        .lines.filter((mutation) => mutation.From === guid);
    },
  };
  static Move = {
    getAll: () => {
      return data.sheets.find((sheet) => sheet.name === "Move").lines;
    },
    getByGuid: (guid) => {
      return this.Move.getAll().find((move) => move.guid === guid);
    },
    getMonsterMovesByGuid: (guid) => {
      return BOK.Monster.getByGuid(guid).Moves;
    },
  };
  static MoveType = {
    getAll: () => {
      return data.sheets.find((sheet) => sheet.name === "MoveType").lines;
    },
    getByGuid: (guid) => {
      return this.MoveType.getAll().find((type) => type.guid === guid);
    },
  };
  static Ability = {
    getAll: () => {
      return data.sheets.find((sheet) => sheet.name === "Ability").lines;
    },
    getByGuid: (guid) => {
      return this.Ability.getAll().find((move) => move.guid === guid);
    },
    getMonsterAbilitiesByGuid: (guid) => {
      return BOK.Monster.getByGuid(guid).Abilities;
    },
  };
  static AbilityRarity = {
    getAll: () => {
      return data.sheets.find((sheet) => sheet.name === "AbilityRarity").lines;
    },
    getByGuid: (guid) => {
      return this.AbilityRarity.getAll().find((type) => type.guid === guid);
    },
  };
  static EggGroup = {
    getAll: () => {
      return data.sheets.find((sheet) => sheet.name === "EggGroup").lines;
    },
    getByGuid: (guid) => {
      return this.EggGroup.getAll().find((move) => move.guid === guid);
    },
    getMonsterEggGroupByGuid: (guid) => {
      return BOK.Monster.getByGuid(guid).EggGroup;
    },
    getMonstersByEggGroup: (guid) => {
      return BOK.Monster.getAll().filter((mon) => mon.EggGroup === guid);
    },
  };
}
