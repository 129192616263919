import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Row, Col, Table, ProgressBar } from "react-bootstrap";
import DB from "./db";

const MonComponent = () => {
  const params = useParams();
  const [monId, setMonId] = useState(params.id);
  const [mon, setMon] = useState(null);
  const navigate = useNavigate();

  const fetchData = () => {
    // Fetch data from API using the monId
    // Replace this with your actual API call
    setMon({ loading: true });
    fetch(`https://nutdex-proxy.vercel.app/metadata/monsters/${monId}`)
      .then((response) => {
        console.log(response);
        if (response.ok) {
          return response.text();
        } else {
          setMon({ error: "ID invalid." });
          throw new Error("Network response was not OK.");
        }
      })
      .then((responseData) => {
        // Store the fetched data in the state
        setMon(JSON.parse(responseData));
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  };
  useEffect(() => {
    if (mon === null && monId) {
      fetchData();
    }
  }, []);

  const handleInputChange = (event) => {
    setMonId(event.target.value);
  };

  const handleButtonClick = () => {
    fetchData();
    navigate("/nft/mon/" + monId);
  };

  return (
    <Col>
      <Row className="py-2 bg-success">
        <Col>
          <input
            className="w-100"
            type="text"
            value={monId}
            onChange={handleInputChange}
          />
        </Col>
        <Col>
          <button className="w-100" onClick={handleButtonClick}>
            Search
          </button>
        </Col>
      </Row>
      {mon && !mon.loading && !mon.error && (
        <>
          <Row>
            <Col className="text-center col-12 px-0">
              <img alt={mon.name} className="w-100 bg-success" width="500" height="500" src={mon.image} />
              <h1 className="mb-0 bg-success text-white">{mon.name}</h1>
            </Col>
          </Row>
          <Row>
            <Col className="px-0 col-12">
              <Table id="mon-stats" className="mb-0">
                <tbody>
                  {rowAttr(mon, "Innate ATK")}
                  {rowAttr(mon, "Innate DEF")}
                  {rowAttr(mon, "Innate HP")}
                  {rowAttr(mon, "Innate sATK")}
                  {rowAttr(mon, "Innate sDEF")}
                  {rowAttr(mon, "Innate SP")}
                  {rowAttr(mon, "Innate SPE")}
                  <tr>
                    <td className="text-white w-25 p-1">Total:</td>
                    <td className="text-white p-1 align-middle">
                      <ProgressBar
                        variant="secondary"
                        striped
                        now={calcPerc(
                          attribute(mon, "Innate ATK") +
                            attribute(mon, "Innate DEF") +
                            attribute(mon, "Innate HP") +
                            attribute(mon, "Innate sATK") +
                            attribute(mon, "Innate sDEF") +
                            attribute(mon, "Innate SP") +
                            attribute(mon, "Innate SPE"),
                          7 * 63
                        )}
                        label={
                          attribute(mon, "Innate ATK") +
                          attribute(mon, "Innate DEF") +
                          attribute(mon, "Innate HP") +
                          attribute(mon, "Innate sATK") +
                          attribute(mon, "Innate sDEF") +
                          attribute(mon, "Innate SP") +
                          attribute(mon, "Innate SPE") +
                          "/" +
                          7 * 63
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-white w-25 p-1">Ability:</td>
                    <td className="text-white p-1 align-middle">
                      {attribute(mon, "Ability")}
                      <span
                        className="badge rounded-pill fw-normal align-middle mx-2"
                        style={{
                          color:
                            DB.Abilities[attribute(mon, "Ability")].grade.color,
                          backgroundColor:
                            DB.Abilities[attribute(mon, "Ability")].grade.bg,
                        }}
                      >
                        {DB.Abilities[attribute(mon, "Ability")].grade.name}
                      </span>
                    </td>
                  </tr>
                  <tr>
                    <td className="text-white w-25 p-1">Variant:</td>
                    <td className="text-white p-1 align-middle">
                      {attribute(mon, "Variant")}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-white w-25 p-1">Breed:</td>
                    <td className="text-white p-1 align-middle">
                      {attribute(mon, "Breed")}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-white w-25 p-1">Stage:</td>
                    <td className="text-white p-1 align-middle">
                      <ProgressBar
                        striped
                        now={calcPerc(
                          attribute(mon, "Mutation Stage"),
                          attributeRaw(mon, "Mutation Stage").max_value
                        )}
                        label={
                          attribute(mon, "Mutation Stage") +
                          "/" +
                          attributeRaw(mon, "Mutation Stage").max_value
                        }
                      />
                    </td>
                  </tr>
                  <tr>
                    <td className="text-white w-25 p-1">Gender:</td>
                    <td className="text-white p-1 align-middle">
                      {attribute(mon, "Gender")}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-white w-25 p-1">Personality:</td>
                    <td className="text-white p-1 align-middle">
                      {attribute(mon, "Personality")}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-white w-25 p-1">Likes:</td>
                    <td className="text-white p-1 align-middle">
                      {attribute(mon, "Likes")}
                    </td>
                  </tr>
                  <tr>
                    <td className="text-white w-25 p-1">Dislikes:</td>
                    <td className="text-white p-1 align-middle">
                      {attribute(mon, "Dislikes")}
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </>
      )}
      {mon && mon.loading && <div className="text-center p-5">Loading...</div>}
      {mon && mon.error && (
        <div className="text-center p-5">Error: {mon.error}</div>
      )}
      {!mon && <div className="text-center p-5">Input an mon ID.</div>}
    </Col>
  );
};

function rowAttr(mon, attr) {
  return (
    <>
      <tr>
        <td className="text-white w-25 p-1">{attr.replace("Innate ", "")}:</td>
        <td className="text-white p-1 align-middle">
          <ProgressBar
            striped
            now={calcPerc(attribute(mon, attr), 63)}
            label={attribute(mon, attr)}
          />
        </td>
      </tr>
    </>
  );
}

function attribute(mon, trait_type) {
  return mon.attributes.filter((obj) => obj.trait_type === trait_type)[0].value;
}
function attributeRaw(mon, trait_type) {
  return mon.attributes.filter((obj) => obj.trait_type === trait_type)[0];
}

function calcPerc(attr, max) {
  return (attr / max) * 100;
}

export default MonComponent;
