import { Col } from "react-bootstrap";
function Notice() {
  return (
    <Col className="col-12 d-flex flex-column p-0 flex-grow-0">
      <small className="bg-dark text-center" style={{ "--bs-bg-opacity": 0.75 }}>
        <a
          className="text-secondary"
          target="_blank"
          href="https://www.spellborne.gg/invite/cinza"
        >
          Play NOW
        </a>
        !
      </small>
    </Col>
  );
}
export default Notice;
