import { Row, Col, Card } from "react-bootstrap";
import BOK from "./BOK.js";
function BOKListComponent() {
  const scrollToTop = () => {
    const container = document.querySelector("#container");
    if (container) {
      container.scrollTop = 0;
    }
  };
  return (
    <>
      <Col className="col-12">
        <Row>
          {BOK.Monster.getAll()
            .sort((a, b) => {
              var iA = a.INDEX.toUpperCase();
              var iB = b.INDEX.toUpperCase();
              if (iA < iB) {
                return -1;
              }
              if (iA > iB) {
                return 1;
              }
              return 0;
            })
            .map((mon) => {
              return (
                <Col className="col-sm-4 col-6 text-center g-2">
                  <a
                    href={"/#/dex/mon/" + mon.INDEX}
                    className="text-decoration-none"
                    onClick={scrollToTop}
                  >
                    <Card className="bg-white overflow-hidden lh-1">
                      <Card.Img
                        className="pixelated h-100"
                        variant="top"
                        src={mon.Image.Base}
                        width="128"
                        height="128"
                      />
                      <Card.Body className="p-1 bg-info ">
                        <Card.Title className="mb-0 fs-6">
                          <small className="badge rounded-pill text-black bg-light">
                            #{mon.INDEX}
                          </small>
                        </Card.Title>
                        <Card.Text clasName="fs-6">
                          {mon.id}
                          <br />
                          <img
                            className="pixelated"
                            alt={
                              BOK.Element.getByGuid(
                                mon.Type1
                              ).id
                            }
                            title={
                              BOK.Element.getByGuid(
                                mon.Type1
                              ).id
                            }
                            src={
                              BOK.Element.getByGuid(
                                mon.Type1
                              ).Image
                            }
                          />
                          <img
                            className="pixelated"
                            alt={
                              BOK.Element.getByGuid(
                                mon.Type2
                              ).id
                            }
                            title={
                              BOK.Element.getByGuid(
                                mon.Type2
                              ).id
                            }
                            src={
                              BOK.Element.getByGuid(
                                mon.Type2
                              ).Image
                            }
                          />
                          <img
                            className="pixelated"
                            alt={
                              BOK.EggGroup.getByGuid(
                                mon.EggGroup
                              ).id
                            }
                            title={
                              BOK.EggGroup.getByGuid(
                                mon.EggGroup
                              ).id
                            }
                            src={
                              BOK.EggGroup.getByGuid(
                                mon.EggGroup
                              ).Image
                            }
                          />
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </a>
                </Col>
              );
            })}
          <Col className="col-12 text-center g-2"></Col>
        </Row>
      </Col>
    </>
  );
}
export default BOKListComponent;
