import { Container, Row, Col, Navbar, NavDropdown } from "react-bootstrap";
import { HashRouter, Routes, Route } from "react-router-dom";
import { LinkContainer } from "react-router-bootstrap";
import MonComponent from "./MonComponent";
import MonsCompareComponent from "./MonsCompareComponent";
import BOKListComponent from "./BOKListComponent.js";
import BOKMonsterComponent from "./BOKMonsterComponent.js";
import Notice from "./Notice";
import logo from "./logo.png";
import BOKMonsterCompareComponent from "./BOKMonsterCompareComponent.js";
import BOKEggGroupComponent from "./BOKEggGroupComponent.js";

function App() {
  return (
    <HashRouter>
      <Container
        id="container"
        className="vh-100 rounded border border-primary border-5 border-end-0 bg-light text-primary d-flex flex-column"
      >
        <div className="sticky-top">
          <Row
            className="bg-secondary border-bottom border-5 border-primary"
            style={{ height: "calc(10vh+18px)" }}
          >
            <Col>
              <img
                src={logo}
                className="position-absolute opacity-25"
                style={{ height: "10vh", zIndex: 0 }}
                alt="NUTDEX"
              />
              <span
                className="bg-success rounded-circle d-inline-block align-middle border border-primary border-2 me-2"
                style={{
                  width: "1em",
                  height: "1em",
                  zIndex: 1,
                  position: "relative",
                }}
              ></span>
              <LinkContainer to="/">
                <button className="btn-none">
                  <h1
                    className="mb-0 d-inline-block align-middle"
                    style={{ lineHeight: "10vh" }}
                  >
                    NUTDEX
                  </h1>
                </button>
              </LinkContainer>
            </Col>
            <Col className="text-end">
              <Navbar
                className="justify-content-end p-0"
                style={{ lineHeight: "10vh" }}
              >
                <NavDropdown
                  className="dropdown-menu-end me-2"
                  title="NFT"
                  id="basic-nav-dropdown"
                >
                  {" "}
                  <LinkContainer to="/nft/mon">
                    <NavDropdown.Item className="lh-1 text-secondary">
                      <button className="btn-none">Mons</button>
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/nft/compare">
                    <NavDropdown.Item className="lh-1 text-secondary">
                      <button className="btn-none">Compare</button>
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
                <NavDropdown
                  className="dropdown-menu-end"
                  title="DEX"
                  id="basic-nav-dropdown"
                >
                  <LinkContainer to="/dex/mon">
                    <NavDropdown.Item className="lh-1 text-secondary">
                      <button className="btn-none">Mons</button>
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/dex/compare">
                    <NavDropdown.Item className="lh-1 text-secondary">
                      <button className="btn-none">Compare</button>
                    </NavDropdown.Item>
                  </LinkContainer>
                  <LinkContainer to="/dex/egggroup">
                    <NavDropdown.Item className="lh-1 text-secondary">
                      <button className="btn-none">Egg Groups</button>
                    </NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              </Navbar>
            </Col>
          </Row>
          <Row>

          </Row>
        </div>
        <Row className="d-flex flex-column flex-grow-1">
          <Notice />
          <Routes>
            <Route exact path="/" element={<BOKListComponent />} />
            <Route exact path="/dex/mon" element={<BOKListComponent />} />
            <Route exact path="/dex/mon/:INDEX" element={<BOKMonsterComponent />} />
            <Route exact path="/dex/compare" element={<BOKMonsterCompareComponent />} />
            <Route exact path="/dex/compare/:MON1/" element={<BOKMonsterCompareComponent />} />
            <Route exact path="/dex/compare/:MON1/:MON2" element={<BOKMonsterCompareComponent />} />
            <Route exact path="/dex/egggroup" element={<BOKEggGroupComponent />} />
            <Route
              exact
              path="/nft/mon"
              element={<MonComponent clear={true} />}
            />
            <Route exact path="/nft/mon/:id" element={<MonComponent />} />
            <Route
              exact
              path="/nft/compare"
              element={<MonsCompareComponent />}
            />
            <Route
              exact
              path="/nft/compare/:id1/:id2"
              element={<MonsCompareComponent />}
            />
          </Routes>
        </Row>
      </Container>
      
    </HashRouter>
  );
}

export default App;
