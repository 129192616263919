import { useParams } from "react-router-dom";
import {
  Row,
  Col,
  Accordion,
  Card,
  OverlayTrigger,
  Tooltip,
  Button,
  Tabs,
  Tab,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import BOK from "./BOK.js";
import RadarChart from "./RadarChart.js";
import { useState } from "react";

function BOKMonsterComponent(props) {
  const [key, setKey] = useState("base");
  const scrollToTop = () => {
    const container = document.querySelector("#container");
    if (container) {
      container.scrollTop = 0;
    }
  };
  const params = useParams();
  const INDEX = props.INDEX || params.INDEX;
  const mon = BOK.Monster.getByIndex(INDEX);
  const hasBaseImage = mon.Image.Base && mon.Image.Base.trim() !== "";
  const hasEnchantedImage =
    mon.Image.Enchanted && mon.Image.Enchanted.trim() !== "";
  const initialKey = hasEnchantedImage ? "enchanted" : "base";
  return (
    <Col className="col-12">
      <Row>
        <Col className="col-4">
          <LinkContainer to="/dex/mon/">
            <button className="btn btn-primary btn-sm lh-1 my-1">
              {"< Back"}
            </button>
          </LinkContainer>
        </Col>
        <Col className="col-4 text-center">
          <h5 className="text-black mb-1 mt-1">#{mon.INDEX}</h5>
        </Col>
        <Col className="col-4"></Col>
        <Col className="col-12 p-0">
          <div className="position-relative text-center bg-white">
            {hasBaseImage && hasEnchantedImage && (
              <Tabs
                id="image-tabs"
                activeKey={key}
                onSelect={(k) => setKey(k)}
                className="position-absolute top-0 end-0 m-2"
                variant="pills"
              >
                {hasBaseImage && (
                  <Tab eventKey="base" title={"Base"} />
                )}
                {hasEnchantedImage && (
                  <Tab eventKey="enchanted" title={"Enchanted"} />
                )}
              </Tabs>
            )}
            <div className="tab-content">
              {key === "base" && hasBaseImage && (
                <img
                  className="w-75 h-100 pixelated"
                  src={mon.Image.Base}
                  alt="Base"
                  width="128"
                  height="128"
                />
              )}
              {key === "enchanted" && hasEnchantedImage && (
                <img
                  className="w-75 h-100 pixelated"
                  src={mon.Image.Enchanted}
                  alt="Enchanted"
                  width="128"
                  height="128"
                />
              )}
            </div>
          </div>
          <div className="text-center bg-info">
            <h3 className="text-black lh-1 mb-0">{mon.id}</h3>
            <p className="mb-0">
              <span className="fs-7 text-black align-middle d-inline">
                <img
                  className="pixelated"
                  alt={BOK.Element.getByGuid(mon.Type1).id}
                  title={BOK.Element.getByGuid(mon.Type1).id}
                  src={BOK.Element.getByGuid(mon.Type1).Image}
                />
                {BOK.Element.getByGuid(mon.Type1).id}
              </span>
              <span>&nbsp;</span>
              <span className="fs-7 text-black align-middle d-inline">
                <img
                  className="pixelated"
                  alt={BOK.Element.getByGuid(mon.Type2).id}
                  title={BOK.Element.getByGuid(mon.Type2).id}
                  src={BOK.Element.getByGuid(mon.Type2).Image}
                />
                {BOK.Element.getByGuid(mon.Type2).id}
              </span>
              <span>&nbsp;</span>
              <span className="fs-7 text-black align-middle d-inline">
                <img
                  className="pixelated"
                  alt={BOK.EggGroup.getByGuid(mon.EggGroup).id}
                  title={BOK.EggGroup.getByGuid(mon.EggGroup).id}
                  src={BOK.EggGroup.getByGuid(mon.EggGroup).Image}
                />
                {
                  BOK.EggGroup.getByGuid(
                    BOK.EggGroup.getMonsterEggGroupByGuid(
                      mon.guid
                    )
                  ).id
                }
              </span>
            </p>
          </div>
          <Accordion defaultActiveKey={[]} alwaysOpen flush>
            <Accordion.Item eventKey="4">
              <Accordion.Header>Description</Accordion.Header>
              <Accordion.Body>
                <span className="fs-7">{mon.Description}</span>
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2">
              <Accordion.Header>Stats</Accordion.Header>
              <Accordion.Body>
                <RadarChart mon={mon} />
              </Accordion.Body>
            </Accordion.Item>
            {BOK.Move.getMonsterMovesByGuid(mon.guid).length > 0 ? (
              <Accordion.Item eventKey="0">
                <Accordion.Header>Moves</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col>
                      <Row className="fs-8 border-bottom text-primary justify-content-center">
                        <Col className="col-1 text-center">
                          Level
                        </Col>
                        <Col className="col-3">Name</Col>
                        <Col className="col-2">Element</Col>
                        <Col className="col-1 col-sm-2 text-center">
                          POW
                        </Col>
                        <Col className="col-1 col-sm-2 text-center">
                          STA
                        </Col>
                        <Col className="col-1 col-sm-2 text-center">
                          ACC
                        </Col>
                      </Row>
                      {BOK.Move.getMonsterMovesByGuid(
                        mon.guid
                      ).map((move) => (
                        <Row className="fs-7 border-bottom justify-content-center">
                          <Col className="col-1 text-center">
                            {move.Level}
                          </Col>
                          <Col className="col-3 lh-1 d-flex align-items-center">
                            <span className="d-sm-none fs-8">
                              {
                                BOK.Move.getByGuid(
                                  move.Move
                                ).id
                              }
                            </span>
                            <span className="d-none d-sm-inline fs-7">
                              {
                                BOK.Move.getByGuid(
                                  move.Move
                                ).id
                              }
                            </span>
                          </Col>
                          <Col
                            className="col-2"
                            style={{
                              whiteSpace: "nowrap",
                            }}
                          >
                            <img
                              className="d-none d-sm-inline pixelated"
                              alt={
                                BOK.Element.getByGuid(
                                  BOK.Move.getByGuid(
                                    move.Move
                                  ).Element
                                ).id
                              }
                              src={
                                BOK.Element.getByGuid(
                                  BOK.Move.getByGuid(
                                    move.Move
                                  ).Element
                                ).Image
                              }
                              style={{
                                width: "16px",
                              }}
                            />
                            <img
                              className="d-sm-none pixelated"
                              alt={
                                BOK.Element.getByGuid(
                                  BOK.Move.getByGuid(
                                    move.Move
                                  ).Element
                                ).id
                              }
                              src={
                                BOK.Element.getByGuid(
                                  BOK.Move.getByGuid(
                                    move.Move
                                  ).Element
                                ).Image
                              }
                              style={{ width: "8px" }}
                            />
                            <span className="d-none d-sm-inline fs-8">
                              {
                                BOK.MoveType.getByGuid(
                                  BOK.Move.getByGuid(
                                    move.Move
                                  ).MoveType
                                ).id
                              }
                            </span>
                            <span className="d-sm-none fs-8">
                              {BOK.MoveType.getByGuid(
                                BOK.Move.getByGuid(
                                  move.Move
                                ).MoveType
                              ).id.substring(0, 4)}
                            </span>
                          </Col>
                          <Col className="col-1 col-sm-2  text-center">
                            {
                              BOK.Move.getByGuid(
                                move.Move
                              ).Power
                            }
                          </Col>
                          <Col className="col-1 col-sm-2  text-center">
                            {
                              BOK.Move.getByGuid(
                                move.Move
                              ).Stamina
                            }
                          </Col>
                          <Col className="col-1 col-sm-2  col-xs-2 text-center">
                            {BOK.Move.getByGuid(
                              move.Move
                            ).Accuracy >= 10000000
                              ? "∞"
                              : BOK.Move.getByGuid(
                                move.Move
                              ).Accuracy.toString()}
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              ""
            )}
            {BOK.Ability.getMonsterAbilitiesByGuid(mon.guid).length >
              0 ? (
              <Accordion.Item eventKey="3">
                <Accordion.Header>Abilities</Accordion.Header>
                <Accordion.Body>
                  <Row className="fs-8 border-bottom text-primary">
                    <Col className="col-3 p-0">Name</Col>
                    <Col className="col-1 p-0">Rarity</Col>
                    <Col className="col-8 p-0">Description</Col>
                  </Row>

                  {BOK.Ability.getMonsterAbilitiesByGuid(
                    mon.guid
                  ).map((ability) => (
                    <Row className="fs-7 border-bottom lh-1">
                      <Col className="col-3 p-0">
                        <span className="">
                          {
                            BOK.Ability.getByGuid(
                              ability.Ability
                            ).id
                          }
                        </span>
                      </Col>
                      <Col className="col-1 p-0 fs-8">
                        <span className="">
                          {
                            BOK.AbilityRarity.getByGuid(
                              BOK.Ability.getByGuid(
                                ability.Ability
                              ).Rarity
                            ).id
                          }
                        </span>
                      </Col>
                      <Col className="col-8 p-0">
                        <span className="">
                          {
                            BOK.Ability.getByGuid(
                              ability.Ability
                            ).Description
                          }
                        </span>
                      </Col>
                    </Row>
                  ))}
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              ""
            )}
            {BOK.Mutation.getMutationsFromByGuid(mon.guid).length > 0 ||
              BOK.Mutation.getMutationsToByGuid(mon.guid).length > 0 ? (
              <Accordion.Item eventKey="1">
                <Accordion.Header>Mutations</Accordion.Header>
                <Accordion.Body>
                  <Row>
                    <Col className="col-6 text-center px-4 mb-2">
                      <h6 className="text-primary">{"<"}</h6>
                      {BOK.Mutation.getMutationsFromByGuid(
                        mon.guid
                      ).map((mutation) => (
                        <>
                          <LinkContainer
                            to={
                              "/dex/mon/" +
                              BOK.Monster.getByGuid(
                                mutation.From
                              ).INDEX
                            }
                            onClick={scrollToTop}
                          >
                            <a
                              className="text-decoration-none"
                              href="#"
                            >
                              <small className="badge rounded-top text-bg-primary text-white w-75">
                                Level{" "}
                                {mutation.Level}
                                {mutation
                                  .SpecialCondition
                                  .length > 0 ? (
                                  <OverlayTrigger
                                    transition={
                                      false
                                    }
                                    placement="top"
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        <span className="text-light lh-1">
                                          {
                                            mutation.SpecialCondition
                                          }
                                        </span>
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant="success"
                                      size="sm"
                                      className="ms-1 py-0 px-1 fs-8 fw-bold"
                                    >
                                      {"!"}
                                    </Button>
                                  </OverlayTrigger>
                                ) : (
                                  <></>
                                )}
                              </small>

                              <Card className="bg-white overflow-hidden mb-2 lh-1">
                                <Card.Img
                                  variant="top"
                                  src={
                                    BOK.Monster.getByGuid(
                                      mutation.From
                                    ).Image.Base
                                  }
                                  className="w-100 mx-auto pixelated"
                                />
                                <Card.Body className="p-1 bg-info ">
                                  <Card.Title className="mb-0 fs-6">
                                    <small className="badge rounded-pill text-bg-secondary">
                                      #
                                      {
                                        mon.INDEX
                                      }
                                    </small>
                                  </Card.Title>
                                  <Card.Text clasName="fs-6">
                                    {
                                      BOK.Monster.getByGuid(
                                        mutation.From
                                      ).id
                                    }
                                    <br />
                                    <img
                                      src={
                                        BOK.Element.getByGuid(
                                          BOK.Monster.getByGuid(
                                            mutation.From
                                          )
                                            .Type1
                                        )
                                          .Image
                                      }
                                      alt={
                                        BOK.Element.getByGuid(
                                          BOK.Monster.getByGuid(
                                            mutation.From
                                          )
                                            .Type1
                                        ).id
                                      }
                                    />
                                    <img
                                      src={
                                        BOK.Element.getByGuid(
                                          BOK.Monster.getByGuid(
                                            mutation.From
                                          )
                                            .Type2
                                        )
                                          .Image
                                      }
                                      alt={
                                        BOK.Element.getByGuid(
                                          BOK.Monster.getByGuid(
                                            mutation.From
                                          )
                                            .Type2
                                        ).id
                                      }
                                    />
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </a>
                          </LinkContainer >
                        </>
                      ))}
                    </Col>
                    <Col className="col-6 text-center px-4 mb-2">
                      <h6 className="text-primary">{">"}</h6>
                      {BOK.Mutation.getMutationsToByGuid(
                        mon.guid
                      ).map((mutation) => (
                        <>
                          <LinkContainer
                            to={
                              "/dex/mon/" +
                              BOK.Monster.getByGuid(
                                mutation.To
                              ).INDEX
                            }
                            onClick={scrollToTop}
                          >
                            <a
                              className="text-decoration-none"
                              href="#"
                            >
                              <small className="badge rounded-top text-bg-primary text-white w-75">
                                Level{" "}
                                {mutation.Level}
                                {mutation
                                  .SpecialCondition
                                  .length > 0 ? (
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip id="button-tooltip">
                                        <span className="text-light lh-1">
                                          {
                                            mutation.SpecialCondition
                                          }
                                        </span>
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      variant="success"
                                      size="sm"
                                      className="ms-1 py-0 px-1 fs-8 fw-bold"
                                    >
                                      {"!"}
                                    </Button>
                                  </OverlayTrigger>
                                ) : (
                                  <></>
                                )}
                              </small>
                              <Card className="bg-white overflow-hidden mb-2 lh-1">
                                <Card.Img
                                  variant="top"
                                  src={
                                    BOK.Monster.getByGuid(
                                      mutation.To
                                    ).Image.Base
                                  }
                                  className="w-100 mx-auto pixelated"
                                />
                                <Card.Body className="p-1 bg-info ">
                                  <Card.Title className="mb-0 fs-6">
                                    <small className="badge rounded-pill text-bg-secondary">
                                      #
                                      {
                                        mon.INDEX
                                      }
                                    </small>
                                  </Card.Title>
                                  <Card.Text clasName="fs-6">
                                    {
                                      BOK.Monster.getByGuid(
                                        mutation.To
                                      ).id
                                    }
                                    <br />
                                    <img
                                      src={
                                        BOK.Element.getByGuid(
                                          BOK.Monster.getByGuid(
                                            mutation.To
                                          )
                                            .Type1
                                        )
                                          .Image
                                      }
                                      alt={
                                        BOK.Element.getByGuid(
                                          BOK.Monster.getByGuid(
                                            mutation.To
                                          )
                                            .Type1
                                        ).id
                                      }
                                    />
                                    <img
                                      src={
                                        BOK.Element.getByGuid(
                                          BOK.Monster.getByGuid(
                                            mutation.To
                                          )
                                            .Type2
                                        )
                                          .Image
                                      }
                                      alt={
                                        BOK.Element.getByGuid(
                                          BOK.Monster.getByGuid(
                                            mutation.To
                                          )
                                            .Type2
                                        ).id
                                      }
                                    />
                                  </Card.Text>
                                </Card.Body>
                              </Card>
                            </a>
                          </LinkContainer>
                        </>
                      ))}
                    </Col>
                  </Row>
                </Accordion.Body>
              </Accordion.Item>
            ) : (
              ""
            )}
          </Accordion>
        </Col >
      </Row>
    </Col>
  );
}
export default BOKMonsterComponent;
